import { Component, Input } from '@angular/core';
import { Asset, AssetsListService, AssetsRankDialogComponent, AssetsSearchDialogComponent, MontageBuilderService, Offer, OfferDomain, OfferPromo, OfferPromoService, OfferService, PromoDomain, ViewMode } from '../..';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizeControlService } from 'pr1-ui-components';
import { PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest, distinctUntilChanged, map, Observable, Subscription, take } from 'rxjs';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, PreviewMode, UtilityService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

const DEFAULT_SEARCH_PLACEHOLDER = 'Search Assets';

@Component({
  selector: 'app-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss']
})
export class AssetsListComponent < T extends Offer,
TOfferDomain extends OfferDomain<Offer>> {
  @Input() context = ''; //Products, Events, Offers, Promos, OfferGroups, Brands, Versions, Coupons
  @Input() contextId = '';
  PreviewMode = PreviewMode;
  ViewMode = ViewMode;
  defaultViewMode = ViewMode.Card;
  searchPlaceholder: string = DEFAULT_SEARCH_PLACEHOLDER;
  searchQuery: string = '';

  showFirstLastButtons = true;
  pageEvent: PageEvent = new PageEvent;
  pageSizeOptions = [5, 10, 20, 50, 100, 500];


  allIdsByContext: string[] = [];

  selection = new SelectionModel<Asset>(true, [], true, (a1, a2) => a1.Detail.Id === a2.Detail.Id);
  displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded', 'DateCreated', 'Rank', 'Actions'];
  flexMediaWatcher!: Subscription;
  assets: Asset[] = [];
  viewModel$ = combineLatest([
    this.assetsListService.assets$,
    this.assetsListService.isLoading$,
    this.assetsListService.totalRecords$,
    this.assetsListService.page$,
    this.montageBuilderService.montageBuilderCheckConfigure$
  ]).pipe(
    map(([assets, isLoading, totalRecords, page,montageBuilderCheckConfigureStatus]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.assetsListService.page(page);
        this.assetsListService.reload();
      }
      this.assets = assets;
      return { assets, isLoading, totalRecords, page,montageBuilderCheckConfigureStatus }
    }),
  );
  selectedAssets:any[] = [];
  constructor(
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    private authorizationService: AuthorizeControlService,
    public utilityService: UtilityService,
    private assetsListService: AssetsListService,
    private offerPromoService: OfferPromoService<OfferPromo, PromoDomain<OfferPromo>>,
    private router: Router,
    private route: ActivatedRoute,
    private montageBuilderService: MontageBuilderService,
    public offerService: OfferService<T, TOfferDomain>,
  ) { }
  getAssetGrid(asset:any){
    this.selectedAssets.push(asset);
    this.montageBuilderService.selectedOfferAssetsBehaviorSubject.next(this.selectedAssets);
  }
  getAssetTable(asset:any){
    this.selectedAssets.push(asset);
    this.montageBuilderService.selectedOfferAssetsBehaviorSubject.next(this.selectedAssets);
  }
  openMontageEditor(){
    // Need to load the Motage Editor
        this.montageBuilderService.montageBuilderStatusBehaviorSubject.next(true);
        this.router.navigate([`../`, `montage-editor`], { relativeTo: this.route });
      }
  ngOnInit() {
    this.assetsListService.context = this.context;
    this.assetsListService.contextId = this.contextId;
    this.getAllIdsByContext();
    this.detectViewportSizeChange();
  }

  ngOnDestroy(): void {
    this.assetsListService.resetServiceState();
  }

  getAllIdsByContext() {
    if (this.context && this.contextId) {
      this.assetsListService.getAllIdsByContext().subscribe((ids: string[]) => {
        this.allIdsByContext = ids;
      });
    }
  }

  canEdit(): boolean {
    return this.authorizationService.checkAccess('edit||offermang');
  }

  detectViewportSizeChange() {
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Actions'];
        } else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded', 'DateCreated', 'Rank', 'Actions'];
        } else {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'Group', 'DateAdded', 'DateCreated', 'Rank', 'Actions'];
        }
      });
  }

  toggleView(mode: ViewMode) {
    this.defaultViewMode = mode;
  }

  openSearchDialog(): void {
    const dialogRef = this.dialog.open(AssetsSearchDialogComponent, {
      width: '100%',
      height: '100%',
      data: {
        // searchPlaceholder: '',
        // searchQuery: '',
        // pageSize: 50,
        // viewMode: "table", //Optional:"table"or "card"(default)
        assetsIds: Array.isArray(this.allIdsByContext) ? this.allIdsByContext : [],
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      // console.info('Assets Search Dialog Component closed!');
    })

    dialogRef.componentInstance.onSelection.subscribe((assetIds: string[]) => {
      this.assetsListService.addAssociationsByContext(assetIds).subscribe({
        next: () => {
          this.matSnackBar.open(`${assetIds.length > 1 ? 'Asset(s)' : 'Asset'} added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.clearSearch();
          this.getAllIdsByContext();
          this.assetsListService.reload();
        },
        error: (error) => {
          console.error('Error adding asset', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    });
  }

  openRankDialog(asset: Asset): void {
    const dialogRef = this.dialog.open(AssetsRankDialogComponent, {
      width: '350px',
      height: '100%',
      data: { asset: asset }
    });

    dialogRef.afterClosed().subscribe(() => {
      // console.info('Asset Rank Dialog Component closed!');
    })

    dialogRef.componentInstance.onSelection.subscribe((res: any) => {
      let asset: Asset = res.asset || null;
      if (asset && res.rank) {
        this.assetsListService.updateAssociationRankByContext(asset.Detail.Id as string, res.rank).subscribe({
          next: () => {
            this.matSnackBar.open(`${asset.Detail.AssetName} rank updated`, 'OK', DEFAULT_SNACKBAR_CONFIG);
            this.clearSearch();
            this.getAllIdsByContext();
            this.assetsListService.reload();
          },
          error: (error) => {
            console.error('Error updating asset rank', error);

            if (error.error.value) {
              throw new Error(error.error.value);
            } else {
              throw new Error(error.message);
            }
          }
        });
      } else {
        console.log('Asset Rank update details', res);
      }
    });
  }

  clearSearch(): void {
    this.searchQuery = '';
    this.onSearch();
  }

  onSearch() {
    this.selection.clear();
    this.assetsListService.search(this.searchQuery);
  }

  onDelete() {
    if (this.selection.selected.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove ${this.selection.selected.length > 1 ? 'Asset(s)' : 'Asset'}?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            const assetIds = this.selection.selected.map((asset: any) => asset.Detail.Id);
            this.assetsListService.removeAssociationsByContext(assetIds).subscribe({
              next: async() => {
                // After deleting checking with image config is there or not
                let offerId = this.route.snapshot.params['offerId']
                await this.offerService.getOffer(offerId).pipe(take(1)).subscribe((offer)=>{
                  let responseOfferDetails = JSON.parse(JSON.stringify(offer));
                  this.montageBuilderService.selectedOfferDetailsBehaviorSubject.next(offer);
                  if (responseOfferDetails && responseOfferDetails.ImageEditorConfigId) {
                    this.montageBuilderService.montageBuilderCheckConfigureBehaviorSubject.next(true);
                    this.montageBuilderService.montageBuilderResponseBehaviorSubject.next(offer);
                  } else {
                    this.montageBuilderService.montageBuilderCheckConfigureBehaviorSubject.next(false);
                    this.montageBuilderService.montageBuilderResponseBehaviorSubject.next(offer);
                  }
                });
                this.matSnackBar.open(`${this.selection.selected.length > 1 ? 'Asset(s)' : 'Asset'} deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.clearSearch();
                this.getAllIdsByContext();
                this.assetsListService.reload();
                
              },
              error: (error) => {
                console.error('Error deleting asset', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  onSort(sortState: Sort): void {
    this.assetsListService.sort(sortState);
    this.selection.clear();
  }

  onPageChange(pageEvent: PageEvent): void {
    this.selection.clear();
    this.assetsListService.page(pageEvent);
  }

  toggleSelect(checked: boolean, asset: any) {
    if (checked) {
      if (!this.selection.isSelected(asset)) {
        this.selection.select(asset);
      }
    } else {
      this.selection.deselect(asset);
    }
  }

  toggleSelectAll(checked: boolean): void {
    if (checked) {
      this.assets.forEach(item => {
        if (!this.selection.isSelected(item)) {
          this.selection.select(item);
        }
      });
    } else {
      this.assets.forEach(item => {
        this.selection.deselect(item);
      });
    }
  }

  isAllSelectedOnPage(): boolean {
    return this.assets.every(item => this.selection.isSelected(item));
  }

  isSomeSelectedOnPage(): boolean {
    return this.assets.some(item => this.selection.isSelected(item));
  }

}
