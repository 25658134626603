import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MontageHistoryPreviewComponent } from '../montage-history-preview/montage-history-preview.component';
import { MontageBuilderService } from '../../services/montage-builder.service';

@Component({
  selector: 'app-montage-history-dialog',
  templateUrl: './montage-history-dialog.component.html',
  styleUrls: ['./montage-history-dialog.component.scss']
})
export class MontageHistoryDialogComponent implements OnInit {
  constructor(public matDialogRef: MatDialogRef<MontageHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private montageBuilderService: MontageBuilderService) { }
  ngOnInit(): void {
  }
  ShowVersionHistory() {
    const imageUrl = this.data.WebUrl; // Replace with your image URL
    const img = new Image();

    img.src = imageUrl;
    img.onload = () => {
      
      const dialogRef =  this.dialog.open(MontageHistoryPreviewComponent, {
        width: '40%',
        height: '600px',
        data: {imageSrc:imageUrl},
        disableClose: true,
      });
      
      dialogRef.afterClosed().subscribe((data)=>{
        if(data=='close'){
          this.matDialogRef.close('close');
        } else {
          this.matDialogRef.close('apply');
        }
      });
    };

    img.onerror = () => {
      console.error('Failed to preload the image');
      // Optionally handle error scenarios, like opening the dialog without the image
    };

   
  }
  CreateNewVersion() {
    this.matDialogRef.close('close');
  }
}
