import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter, OrganizationService } from 'src/app/@shared';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { MatDialog } from '@angular/material/dialog';
import { ImageEditorConfigDomainsResponse, MontageImageDetails } from '../models/montage-image-details.model';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { v4 as uuidv4 } from 'uuid';
import { EventDomain } from '../models/event-domain.model';


const DEFAULT_DATE_RANGE = {
  startDate: '',
  endDate: '',
};

@Injectable({
  providedIn: 'root',
})

export class MontageBuilderService {

  // initialize behavior subjects
  public selectedOfferAssetsBehaviorSubject = new BehaviorSubject<any>([]);
  public selectedOfferProductsBehaviorSubject = new BehaviorSubject<any>([]);
  public selectedOfferDetailsBehaviorSubject = new BehaviorSubject<any>({});
  public montageBuilderStatusBehaviorSubject = new BehaviorSubject<boolean>(false);
  public montageBuilderCheckConfigureBehaviorSubject = new BehaviorSubject<boolean>(false);
  public montageBuilderResponseBehaviorSubject = new BehaviorSubject<any>({});
  public montageShowVersionHistoryBehaviorSubject = new BehaviorSubject<any>({});
  public montageCreateNewVersionBehaviourSubject = new BehaviorSubject<boolean>(false);
  public montageOfferSelectedFromEventBehaviourSubject = new BehaviorSubject<string>('');

  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private sortBehaviorSubject = new BehaviorSubject({ active: 'AssetName', direction: 'desc', });
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private productOfferIdBehaviorSubject = new BehaviorSubject<string>('');

  private notOfferProductLoadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogsortBehaviorSubject = new BehaviorSubject({ active: 'ProductName', direction: 'asc', });
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');


  // we do not wish to expose our behavior subjects.  create public observables

  public montageBuilderStatus$ = this.montageBuilderStatusBehaviorSubject.asObservable();
  public montageBuilderCheckConfigure$ = this.montageBuilderCheckConfigureBehaviorSubject.asObservable();
  public selectedOfferProducts$ = this.selectedOfferProductsBehaviorSubject.asObservable();
  public selectedOfferDetails$ = this.selectedOfferDetailsBehaviorSubject.asObservable();
  public selectedOfferAssets$ = this.selectedOfferAssetsBehaviorSubject.asObservable();
  public montageBuilderResponses$ = this.montageBuilderResponseBehaviorSubject.asObservable();
  public montageShowVersionHistory$ = this.montageShowVersionHistoryBehaviorSubject.asObservable();
  public montageCreateNewVersion$ = this.montageCreateNewVersionBehaviourSubject.asObservable();
  public montageOfferSelectedFromEvent$ = this.montageOfferSelectedFromEventBehaviourSubject.asObservable();

  public page$ = this.pageBehaviorSubject.asObservable();
  public notOfferProductPage$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public dialogsort$ = this.dialogsortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public notOfferProductisLoading$ = this.notOfferProductLoadingBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public productOfferId$ = this.productOfferIdBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private dialog: MatDialog) { }


  getAllMontageImageEditorConfiguration(): Observable<ImageEditorConfigDomainsResponse[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ImageEditorConfigDomains/GetAll`;
    return this.httpClient.get<ImageEditorConfigDomainsResponse[]>(url);
  }

  // Image Editor Configuration by Offer Id
  getMontageImageEditorConfiguration(offerId: string): Observable<ImageEditorConfigDomainsResponse> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ImageEditorConfigDomains/${offerId}`;
    return this.httpClient.get<ImageEditorConfigDomainsResponse>(url);
  }

  // Create Montage Image Editor Configuration
  createMontageImageEditorConfiguration(offerId: any): Observable<ImageEditorConfigDomainsResponse> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ImageEditorConfigDomains/Post`;
    return this.httpClient.post<ImageEditorConfigDomainsResponse>(url, offerId);
  }

  // Update Montage Image Editor Configuration
  updateMontageImageEditorConfiguration(offerId: any): Observable<ImageEditorConfigDomainsResponse> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ImageEditorConfigDomains/Put`;
    return this.httpClient.put<ImageEditorConfigDomainsResponse>(url, offerId);
  }

  // Delete Montage Image Editor Configuration
  deleteMontageImageEditorConfiguration(offerId: string): Observable<ImageEditorConfigDomainsResponse> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ImageEditorConfigDomains/${offerId}`;
    return this.httpClient.delete<ImageEditorConfigDomainsResponse>(url);
  }

  // Get All Product Assets Details  by Ids
  getByProductIds(productIds: string): Observable<MontageImageDetails> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetByProductIds?${productIds}`;
    return this.httpClient.get<MontageImageDetails>(url);
  }
  //  Get All Assets Details by Ids
  getByAssetIds(assetIds: string): Observable<MontageImageDetails> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetByAssetIds?${assetIds}`;
    return this.httpClient.get<MontageImageDetails>(url);
  }

  //  Get All Assets Details by Ids
  GetByAssets(assetIds: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ImageEditorConfigDomains/GetByAssets?${assetIds}`;
    return this.httpClient.get<any>(url);
  }

  //  Get All Assets Details by Ids
  DeleteByClientKey(ClientKey: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/DeleteByClientKey?clientKey=${ClientKey}`;
    return this.httpClient.delete<any>(url);
  }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.montageOfferSelectedFromEventBehaviourSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.dateRangeBehaviorSubject.pipe(debounceTime(50)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([productOfferId, page, dateRange, sort, search, filters, reload]) => {

      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'DivisionNames' || sort.active == 'EventTypeName') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          offerId: this.montageOfferSelectedFromEventBehaviourSubject.value,
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: _orderby,
          $count: true,
        }
      });

      if (dateRange.startDate) {
        params = params.append('startDateAdded', `${dayjs(dateRange.startDate).format('MM/DD/YYYY')}`);
      }
      if (dateRange.endDate) {
        params = params.append('endDateAdded', `${dayjs(dateRange.endDate).format('MM/DD/YYYY')}`);
      }

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParams(filters, params);
      }

      return params;
    })
  );
  set offerId(offerId: string) {
    this.productOfferIdBehaviorSubject.next(offerId);
  }
  // create the events observable that calls http get when any of our parameters change
  private assetsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetOfferAssetsAndProductsAssetsByOfferId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // event listing
  public assetsList$: Observable<any[]> = this.assetsResponse$.pipe(
    map((res: any) => res.value)
  );
  // total number of event records based on filtering
  public totalRecords$: Observable<number> = this.assetsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  reload() {
    this.reloadBehaviorSubject.next(uuidv4());
  }

  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  search(search: string) {
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }
  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    const viewMode = this.viewModeBehaviorSubject.value;
    const range = { startDate: start as string, endDate: end as string };
    this.dateRangeBehaviorSubject.next(range);
    if (viewMode == "EXPANSION") {
      let paging = JSON.parse(JSON.stringify(DEFAULT_PAGING));
      paging.pageSize = 1000;
      this.pageBehaviorSubject.next(paging);
    } else {
      this.pageBehaviorSubject.next(DEFAULT_PAGING);
    }
  }

  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach(filter => {
      if (filters.findIndex(item => item.fieldName === filter.fieldName && item.value === filter.value) === -1) {
        filters.push(filter)
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(item => item.fieldName !== fieldName);
    this.filterBehaviorSubject.next(filters)
  }

  // removes all filters for the event listing
  clearFilters() {
    this.dateRangeBehaviorSubject.next(DEFAULT_DATE_RANGE);
    this.filterBehaviorSubject.next([]);
  }
  resetServiceState(): void {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
    this.loadingBehaviorSubject.next(false);
    this.searchBehaviorSubject.next('');
    this.sortBehaviorSubject.next({ active: 'AssetName', direction: 'asc' });
    this.reloadBehaviorSubject.next('');
  }
  // build the list of filter parameters
  private buildFilterParams(filters: Filter[], params: HttpParams): HttpParams {
    // get the division id filters
    const assetGroupIds = filters.filter(item => item.fieldName === 'assetGroupIds');

    // loop through the division id filters and add filter statement to param
    assetGroupIds.forEach((filter) => {
      params = params.append('assetGroupIds', filter.value);
    });

    // get the event type id filter
    const fileTypes = filters.filter(item => item.fieldName === 'fileTypes');


    fileTypes.forEach((filter) => {
      params = params.append('fileTypes', filter.value);
    });


    // return the params
    return params;
  }



}
