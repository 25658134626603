<ng-container>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
            <h2>Please Confirm</h2>
            <p>Are you sure you want to upload this historical record?</p>
        </div>
        <button mat-icon-button (click)="Cancel()" aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="preview-section">
        <p>Preview</p>
        <img *ngIf="imageUrl" style="max-height: 400px; max-width: 400px; object-fit: contain" [src]="imageUrl" alt="Montage image">
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
    
        <button type="button" style="    width: 15% !important;" mat-stroked-button color="accent" matTooltip="Cancel"
            data-testid="Cancel" (click)="Cancel()">
            Cancel
        </button>
        <button type="button" style="    width: 15% !important;" mat-raised-button color="accent" matTooltip="Apply"
            data-testid="Apply" (click)="Apply()">
            Apply
        </button>
    </div>
</ng-container>