import { Component } from '@angular/core';
import { OfferPromoService } from '../../services/offerpromo.service';
import { OfferPromo } from '../../models/offer-promo.model';
import { PromoDomain } from '../../models/promo-domain.model';
import Konva from 'konva';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { DEFAULT_SNACKBAR_CONFIG, OrganizationService } from 'src/app/@shared';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MontageBuilderService } from '../../services/montage-builder.service';
import { AssetsService } from '../../services/assets.service';
import { Asset } from '../../models/asset.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssetTagService } from '../../services/asset-tag.service';
import { AssetTag } from '../../models/asset-tag.model';
import { AssetGroup } from '../../models/asset-group.model';
import { MontageAssetsListComponent } from '../montage-assets-list/montage-assets-list.component';
import { ImageEditorConfigDomainsResponse, MontageImageDetails } from '../../models/montage-image-details.model';
import { MontageHistoryDialogComponent } from '../montage-history-dialog/montage-history-dialog.component';
import { OfferService } from '../../services/offer.service';
import { Offer } from '../../models/offer.model';
import { OfferDomain } from '../../models/offer-domain.model';
import { MontageHistoryPreviewComponent } from '../montage-history-preview/montage-history-preview.component';
type montageImage = {
  id: string;
  image: string;
  name: string;
  wicId: string;
  imageName?: string;
  ClientKey: string;
};
type imgDrawConfig = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  currentScale: number;
};
@Component({
  selector: 'app-montage-editor',
  templateUrl: './montage-editor.component.html',
  styleUrls: ['./montage-editor.component.scss'],
})
export class MontageEditorComponent<
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>,
  ATag extends AssetTag,
  Group extends AssetGroup,
  T extends Offer,
  TOfferDomain extends OfferDomain<Offer>,
> {
  montageBuilderStatus: boolean = false;
  layoutSelected: string = 'Layout';
  public montageImages: any[] = [];
  public isMontageEdit: boolean = false;
  public MontageOfferFieldInfo = {};
  public featureConfig = {};
  public montageConfig: any;
  imagesConfigs: any = {};
  public montageImagesConfigs: null = null;
  data: any;
  allImgRefs: any;
  stage: any;
  viewScale = 0.35;
  viewHeight = 50;
  viewWidth = 0.5;
  currentFileId: string = '';

  montageName: string = '';
  IsUniqueMontage: boolean = false;
  selectedImage: {
    x: number;
    y: number;
    height: number;
    width: number;
    ref: any;
    layerRef: any;
    scale: any;
  } = {
      x: 0,
      y: 0,
      height: 180,
      width: 180,
      ref: null,
      layerRef: null,
      scale: 0,
    };
  montageHistoryConfig: any;
  ImageEditorConfigId = '';
  guestForm = new FormGroup({
    search: new FormControl<string | null | undefined>(null),
  });

  offerId: string = '';
  eventId: string = '';
  oldMontageConfig: boolean = false;
  montageShowVersionHistory: any = {};
  offerDetails: any = {};
  constructor(
    private assetsService: AssetsService<Asset>,
    private offerPromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private dialog: MatDialog,
    private montageBuilderService: MontageBuilderService,
    private route: ActivatedRoute,
    private router: Router,
    private organizationService: OrganizationService,
    private matSnackBar: MatSnackBar,
    private assetTagService: AssetTagService<ATag, Group>,
    public offerService: OfferService<T, TOfferDomain>,
  ) { }

  async ngOnInit() {

    try {
      this.ImageEditorConfigId = '';
      this.montageShowVersionHistory = {};
      this.offerId = this.route.snapshot.params['offerId'];
      this.eventId = this.route.snapshot.params['eventId'];
      await firstValueFrom(this.montageBuilderService.montageBuilderResponses$.pipe(take(1))).then((data) => {
        this.offerDetails = data;
        console.log('montage offer details',data);
      }).catch(err=>{

      })
      await firstValueFrom(
        this.montageBuilderService.montageBuilderStatus$.pipe(take(1))
      )
        .then((data) => {
          this.montageBuilderStatus = data;
        })
        .catch((err) => {

        });

      await firstValueFrom(
        this.montageBuilderService.montageBuilderResponses$.pipe(take(1))
      )
        .then((datas) => {
          this.ImageEditorConfigId = datas.ImageEditorConfigId;
        })
        .catch((err) => {

        });
      if (this.ImageEditorConfigId) {
        await firstValueFrom(
          this.montageBuilderService
            .getMontageImageEditorConfiguration(this.ImageEditorConfigId)
            .pipe(take(1))
        )
          .then((res: ImageEditorConfigDomainsResponse) => {
            res.ChildAssets.forEach((asset: any) => {
              let config = JSON.parse(asset.Config);
              this.imagesConfigs[`${config.montageImageConfig.refId}`] = {
                ...config.montageImageConfig,
              };
              this.montageImages.push(config.montageImageDetails);
            });
          })
          .catch((err) => {

          });
        await this.selectedOfferProducts();
        await this.getselectedOfferAssets();
        await this.sortImageAndLoadMontage();

      } else {

        // We need to check api call wheather need to use existing data or new data selected from assets
        await this.selectedOfferProducts();
        await this.getselectedOfferAssets();

        let urlParams = '';

        for (
          let index = 0;
          index <= this.montageImages.length - 1;
          index++
        ) {
          urlParams += `assetIds=${this.montageImages[index]?.id}`;
          if (index != this.montageImages.length - 1) {
            urlParams += '&';
          }
        }
        if (this.montageImages.length > 0) {
          await firstValueFrom(this.montageBuilderService.GetByAssets(urlParams).pipe(take(1))).then(async (data) => {

            if (data.value.length > 0) {
              // if (data.value[0].Detail.Id) {
              //   this.ImageEditorConfigId = data.value[0].Detail.Id;
              // }
              this.oldMontageConfig = true;
              this.montageShowVersionHistory = data.value[0];
              this.montageBuilderService.montageShowVersionHistoryBehaviorSubject.next(data.value[0]);
              const dialogRef = this.dialog.open(MontageHistoryDialogComponent, {
                width: '40%',
                height: '40%',
                data: data.value[0],
                disableClose: true,
              });
              dialogRef.afterClosed().subscribe(async (data) => {
                if (data == 'close') {
                  // selected image need load
                  this.oldMontageConfig = false;
                  await this.selectedOfferProducts();
                  await this.getselectedOfferAssets();
                  await this.sortImageAndLoadMontage();
                } else {
                  // need to load api response in the screen
                  this.montageImages = [];
                  this.imagesConfigs = {};
                  if (this.montageShowVersionHistory.ChildAssets.length > 0) {
                    this.montageShowVersionHistory.ChildAssets.forEach((asset: any) => {
                      let config = JSON.parse(asset.Config);
                      this.imagesConfigs[`${config.montageImageConfig.refId}`] = {
                        ...config.montageImageConfig,
                      };
                      this.montageImages.push(config.montageImageDetails);
                    });
                  }
                  await this.sortImageAndLoadMontage();
                }
              });

            } else {
              await this.selectedOfferProducts();
              await this.getselectedOfferAssets();
              await this.sortImageAndLoadMontage();
            }
          }).catch(err => {

          });
        }
      }
    } catch (error) {

    }
  }

  async selectedOfferProducts() {
    await firstValueFrom(
      this.montageBuilderService.selectedOfferProducts$.pipe(take(1))
    )
      .then(async (data) => {
        if (data.length > 0) {
          let imageConfigsLength = Object.keys(this.imagesConfigs).length;
          // Need to filter the FacingAsset is null object and call API
          let getFacingAssetsArr = data;
          let getFacingAssetsNotNullArr = data;
          let getFacingAssets = getFacingAssetsArr.filter(
            (product: any) => product.FacingAsset == null
          );
          let getFacingAssetsNotNull = getFacingAssetsNotNullArr.filter(
            (product: any) => product.FacingAsset != null
          );
          if (getFacingAssets.length > 0) {
            let urlParams = '';

            for (
              let index = 0;
              index <= getFacingAssets.length - 1;
              index++
            ) {
              urlParams += `productIds=${getFacingAssets[index]?.Detail.Id}`;
              if (index != getFacingAssets.length - 1) {
                urlParams += '&';
              }
            }
            await firstValueFrom(
              this.montageBuilderService
                .getByProductIds(urlParams)
                .pipe(take(1))
            )
              .then((data: MontageImageDetails) => {
                data.value.map((product: any) => {
                  if (product && product.Id && product.WebUrl) {
                    let objectMontageConfig = {
                      imageName: product.FileName,
                      id: product.Id || '',
                      name: product.FileName || '',
                      wicId: product.Id,
                      image: product.WebUrl,
                      ClientKey: product.ClientKey || ''
                    };
                    const found = this.montageImages.some(
                      (el) => el.wicId === product.Id
                    );
                    if (!found) {
                      this.montageImages.push(objectMontageConfig);

                      let upc = product.Id;
                      if (upc) {
                        this.imagesConfigs[`${upc}`] = {
                          x: 180,
                          y: 180,
                          refId: upc,
                          scaleX: 0.5,
                          scaleY: 0.5,
                          rotation: 0,
                          layerIndex: imageConfigsLength,
                        };
                        imageConfigsLength = imageConfigsLength + 1;
                      }
                    }
                  }
                });
              })
              .catch((err) => {

              });
          }
          getFacingAssetsNotNull.map((product: any) => {
            if (product && product.Detail.Id && product.FacingAsset.WebUrl) {
              let objectMontageConfig = {
                name: product.FacingAsset.FileName,
                id: product.Detail.Id || '',
                imageName: product.FacingAsset.FileName,
                wicId: product.FacingAsset.AssetId,
                image: product.FacingAsset.WebUrl,
                ClientKey: product.Detail.ClientKey
              };
              this.montageImages.push(objectMontageConfig);

              let upc = product.Detail.Id;
              if (upc) {
                this.imagesConfigs[`${upc}`] = {
                  x: 180,
                  y: 180,
                  refId: upc,
                  scaleX: 0.5,
                  scaleY: 0.5,
                  rotation: 0,
                  layerIndex: imageConfigsLength,
                };
                imageConfigsLength = imageConfigsLength + 1;
              }
            }
          });
        }
      })
      .catch((err) => {

      });
  }
  async getselectedOfferAssets() {
    await firstValueFrom(
      this.montageBuilderService.selectedOfferAssets$.pipe(take(1))
    )
      .then(async (data) => {
        // Need to filter the WebUrl is null object and call API
        if (data.length > 0) {
          let imageConfigsLength = Object.keys(this.imagesConfigs).length;
          let getFacingAssetsArr = data;
          let getFacingAssetsNotNullArr = data;
          let getFacingAssets = getFacingAssetsArr.filter(
            (product: any) => product.Detail.WebUrl == null
          );
          let getFacingAssetsNotNull = getFacingAssetsNotNullArr.filter(
            (product: any) => product.Detail.WebUrl != null
          );
          if (getFacingAssets.length > 0) {
            let urlParams = '';

            for (
              let index = 0;
              index <= getFacingAssets.length - 1;
              index++
            ) {
              urlParams += `assetIds=${getFacingAssets[index]?.Detail.Id}`;
              if (index != getFacingAssets.length - 1) {
                urlParams += '&';
              }
            }
            await firstValueFrom(
              this.montageBuilderService
                .getByAssetIds(urlParams)
                .pipe(take(1))
            )
              .then((product: MontageImageDetails) => {
                product.value.map((product: any) => {
                  if (product && product.Detail.Id && product.Detail.WebUrl) {
                    let objectMontageConfig = {
                      imageName: product.Detail.FileName || '',
                      wicId: product.Detail.Id || '',
                      image: product.Detail.WebUrl || '',
                      name: product.Detail.FileName || '',
                      id: product.Detail.Id || '',
                      ClientKey: product.Detail.ClientKey || ''
                    };
                    const found = this.montageImages.some(
                      (el) => el.wicId === product.Detail.Id
                    );
                    if (!found) {
                      this.montageImages.push(objectMontageConfig);

                      let upc = product.Detail.Id;
                      if (upc) {
                        this.imagesConfigs[`${upc}`] = {
                          x: 180,
                          y: 180,
                          refId: upc,
                          scaleX: 0.5,
                          scaleY: 0.5,
                          rotation: 0,
                          layerIndex: imageConfigsLength,
                        };
                        imageConfigsLength = imageConfigsLength + 1;
                      }
                    }
                  }
                });
              })
              .catch((err) => {

              });
          }
          getFacingAssetsNotNull.map((product: any) => {
            if (product && product.Detail.Id && product.Detail.WebUrl) {
              let objectMontageConfig = {
                imageName: product.Detail.FileName || '',
                wicId: product.Detail.Id || '',
                image: product.Detail.WebUrl || '',
                name: product.Detail.FileName || '',
                id: product.Detail.Id || '',
                ClientKey: product.Detail.ClientKey || ''
              };
              const found = this.montageImages.some(
                (el) => el.wicId === product.Detail.Id
              );
              if (!found) {
                this.montageImages.push(objectMontageConfig);

                let upc = product.Detail.Id;
                if (upc) {
                  this.imagesConfigs[`${upc}`] = {
                    x: 180,
                    y: 180,
                    refId: upc,
                    scaleX: 0.5,
                    scaleY: 0.5,
                    rotation: 0,
                    layerIndex: imageConfigsLength,
                  };
                  imageConfigsLength = imageConfigsLength + 1;
                }
              }
            }
          });
        }
      })
      .catch((err) => {

      });
  }
  showVersionHistory() {
    //this.montageBuilderService.montageShowVersionHistoryBehaviorSubject

    if (Object.keys(this.montageShowVersionHistory).length) {
      const dialogRef = this.dialog.open(MontageHistoryPreviewComponent, {
        width: '40%',
        height: '600px',
        data: this.montageShowVersionHistory,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(async (data) => {
        if (data == 'close') {
          // selected image need load
          this.oldMontageConfig = false;
          await this.sortImageAndLoadMontage();
        } else {
          // need to load api response in the screen
          this.montageImages = [];
          this.imagesConfigs = {};
          if (this.montageShowVersionHistory.ChildAssets.length > 0) {
            this.montageShowVersionHistory.ChildAssets.forEach((asset: any) => {
              let config = JSON.parse(asset.Config);
              this.imagesConfigs[`${config.montageImageConfig.refId}`] = {
                ...config.montageImageConfig,
              };
              this.montageImages.push(config.montageImageDetails);
            });
          }
          await this.sortImageAndLoadMontage();
        }
      });
    }
  }
  layoutDesign(layoutSelected: string) {
    this.layoutSelected = layoutSelected;
  }
  close() {
    this.montageBuilderService.montageBuilderStatusBehaviorSubject.next(false);
    this.montageBuilderService.selectedOfferProductsBehaviorSubject.next([]);
    this.montageBuilderService.selectedOfferAssetsBehaviorSubject.next([]);
    this.montageBuilderService.montageBuilderResponseBehaviorSubject.next({});

    this.router.navigate(
      [`../${this.route.snapshot.params['offerId']}`, `assets`],
      {
        queryParams: { offer_filters: true },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
      }
    );
  }
  async loadMontageImages(montageImages: montageImage[]) {
    this.stage = new Konva.Stage({
      container: 'container',
      width: 1000,
      height: 1000,
    });
    if (montageImages) {
      this.allImgRefs = [];

      for (let mi = 0; mi < montageImages.length; mi++) {
        let offset = ((mi + 1) * 180) / 2;

        const mImage = montageImages[mi];

        const layer = new Konva.Layer();
        this.stage.add(layer);

        await Konva.Image.fromURL(mImage.image, (img) => {
          let imgOldConfig =
            this.imagesConfigs && this.imagesConfigs[mImage.wicId]
              ? this.imagesConfigs[mImage.wicId]
              : {};

          img.setAttrs({
            scaleX: 0.5,
            scaleY: 0.5,
            x: offset,
            y: offset,
            crossOrigin: 'Anonymous',
            refId: mImage.wicId,
            draggable: true,
            strokeWidth: 1,
            ...imgOldConfig,
          });

          // img.crossOrigin = 'Anonymous';
          layer.add(img);

          img.on('dragend dragmove', () => this.selectImageTransformer(img));

          const tr = new Konva.Transformer({
            enabledAnchors: [
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right',
            ],
          });
          layer.add(tr);

          layer.draw();
          this.setImageConfig(img);
        });

        this.stage.on('click', (e: any) => {
          let target = e.target;

          if (target == this.stage) {
            this.clearTransformers();
          }

          if (target.getClassName() == 'Image') {
            this.selectImageTransformer(target);
          }
        });
      }
    }
  }
  selectImageTransformer(imgRef: any) {
    this.clearTransformers();

    let layer = imgRef.parent;
    let transformer = layer.children.filter(
      (lc: any) => lc.getClassName() == 'Transformer'
    );
    if (transformer[0]) {
      transformer[0].nodes([imgRef]);
    }

    this.setImageConfig(imgRef);
  }
  setImageConfig(imgRef: any) {
    const { x, y, refId, scaleX, scaleY, rotation } = imgRef.attrs;
    this.imagesConfigs[refId] = {
      x,
      y,
      refId,
      scaleX,
      scaleY,
      rotation,
      layerIndex: imgRef.parent.index,
    };
  }

  clearTransformers() {
    for (const layer of this.stage.children) {
      let transformer = layer.children.filter(
        (lc: any) => lc.getClassName() == 'Transformer'
      );
      if (transformer[0]) {
        transformer[0].nodes([]);
      }
    }
  }

  async sortImageAndLoadMontage() {
    this.montageImages = await this.sortImgsByConfig(this.montageImages);
    await this.loadMontageImages(this.montageImages);
  }
  // Sort images based on config
  sortImgsByConfig(montageImages: montageImage[]): montageImage[] {
    let imgConfig = this.imagesConfigs;
    if (imgConfig) {
      montageImages = JSON.parse(JSON.stringify(montageImages)); // deep clone
      montageImages.sort((a: montageImage, b: montageImage) => {
        let aIndex = imgConfig[a.wicId] && imgConfig[a.wicId]['layerIndex'],
          bIndex = imgConfig[b.wicId] && imgConfig[b.wicId]['layerIndex'];

        if (aIndex < bIndex) {
          return -1;
        }
        if (aIndex > bIndex) {
          return 1;
        }
        return 0;
      });
    }

    return montageImages;
  }
  // Delete image from Montage builder
  async onDelete(selectedImage: any) {
    this.montageImages = this.montageImages.filter(function (obj) {
      return obj.wicId !== selectedImage.wicId;
    });
    delete this.imagesConfigs[`${selectedImage.refId}`];
    // Need to reassign layering values after  delete a number from imageConfigs 
    if (this.stage) {
      this.stage.clear();
    }
    await this.sortImageAndLoadMontage();
  }
  sortByPosition(obj: any) {
    const order: any = [], res: any = {};
    Object.keys(obj).forEach(key => {
      return order[obj[key]['layerIndex'] - 1] = key;
    });
    order.forEach((key: any) => {
      res[key] = obj[key];
    });
    return res;
  }
  onReorder() {
    this.montageImages = this.sortImgsByConfig(this.montageImages);

    for (const layer of this.stage.children) {
      let image = layer.children.find(
        (lc: any) => lc.getClassName() == 'Image'
      );

      if (image)
        layer.setZIndex(this.imagesConfigs[image.attrs['refId']]['layerIndex']);
    }
  }

  getImageConfig(): imgDrawConfig | null {
    return null;
  }
  clearStage() {
    if (this.stage) {
      this.stage.clear();
    } else {
      this.stage = new Konva.Stage({
        container: 'container',
        width: 1000,
        height: 1000,
      });
    }
  }

  base64ToBlob(base64: any, mime: any) {
    mime = mime || '';
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];

    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }

  setAllImgConfigs() {
    try {
      if (this.stage && this.stage.children)
        for (const layer of this.stage.children) {
          for (const child of layer.children) {
            if (child.getClassName() == 'Image') {
              this.setImageConfig(child);
            }
          }
        }
    } catch (error) {
      console.log(error);
    }
  }

  getFileId(): string {
    //  let fileExt="png";
    let fileKeys: string[] = [...this.montageImages].map((m) => {
      let imgName: string = m.ClientKey ? m.ClientKey : m.imageName;
      if (!imgName) return '';
      imgName = imgName.toLowerCase().trim();
      if (imgName.indexOf(".") != -1) {
        // remove extension
        let imgSplit = imgName.split(".");
        imgSplit.pop();
        //let ext = imgSplit.pop();
        //if(!fileExt)fileExt=ext;
        imgName = imgSplit.join("");
      }
      return imgName.slice(-6);
    });
    // if(!fileExt)fileExt="png";
    return fileKeys && fileKeys.length ? fileKeys.join('_') : '';
  }

  getFileName(): string {
    return `M_${this.currentFileId}.png`;
  }
  async saveUniqueMontage() {
    this.IsUniqueMontage = true;
    await this.saveMontageConfig();
    this.IsUniqueMontage = false;
  }
  async saveMontageConfig() {
    try {
      this.clearTransformers();

      this.setAllImgConfigs();
      let dataURL = this.stage.toDataURL({ mimeType: 'image/png' });
      //this.downloadURI(dataURL, (this.montageName || "montage") + ".png");

      let base64ImageContent = dataURL.replace(
        /^data:image\/(png|jpg);base64,/,
        ''
      );
      let blob = this.base64ToBlob(base64ImageContent, 'image/png');

      let imageFile: any = '';
      let montageimageClientKey = '';
      if (this.IsUniqueMontage == true) {
        montageimageClientKey = `M_${this.getFileId()}_U`;
        imageFile = new File([blob], `M_${this.getFileId()}_U.png`, {
          type: 'image/png',
        });
      } else {
        montageimageClientKey = `M_${this.getFileId()}`;
        imageFile = new File([blob], `M_${this.getFileId()}.png`, {
          type: 'image/png',
        });
      }
      let AssetGroupId = '';
      await firstValueFrom(
        this.assetTagService.assetGroups$.pipe(take(1))
      ).then((data: any) => {
        if (data.length > 0) {
          let getObj = data.find(
            (groupName: any) => groupName.AssetGroupName == 'Offers'
          );
          AssetGroupId = getObj.Id;
        }
      }).catch(err => {
        console.log('Get Asset Group Name is failed', err);
      });
      const formData: FormData = new FormData();
      formData.append('File', imageFile);
      formData.append('AssetGroupId', AssetGroupId);
      formData.append('EventId', this.route.snapshot.params['eventId']);
      formData.append('OfferId', this.route.snapshot.params['offerId']);
      let createMontageConfiguration: any = {};
      await firstValueFrom(
        this.montageBuilderService.selectedOfferDetails$.pipe(take(1))
      ).then((offerDetails) => {
        let Detail: any = {
          ClientKey: offerDetails.Detail.ClientKey,
          Name: offerDetails.Detail.Name,
          Config: '',
          IsUniqueMontage: this.IsUniqueMontage
        };
        if (this.ImageEditorConfigId) {
          Detail['Id'] = this.ImageEditorConfigId
        }
        createMontageConfiguration.Detail = { ...Detail };
        createMontageConfiguration.OfferId = offerDetails.Detail.Id;
      });
      await firstValueFrom(this.montageBuilderService.DeleteByClientKey(montageimageClientKey).pipe(take(1))).then((data) => {
        if (data && data.Id) {

        }
      }).catch(err => {

      });

      await this.assetsService
        .uploadAsset(formData)
        .pipe(take(1))
        .subscribe({
          next: async (event: any) => {
            let resp = { ...this.imagesConfigs };
            let respon = this.montageImages;
            let responseResult: any = [];
            respon.forEach((res) => {
              let montageImageDetails = {
                id: res.id || '',
                image: res.image || '',
                name: res.name || '',
                wicId: res.wicId || '',
                imageName: res.imageName || '',
                ClientKey: res.ClientKey || ''
              };
              let montageImageConfig = this.imagesConfigs[`${res.wicId}`];
              let montageConfig = {
                montageImageConfig: montageImageConfig,
                montageImageDetails: montageImageDetails,
              };
              let obj = {
                AssetId: res.wicId,
                Config: JSON.stringify(montageConfig),
              };
              responseResult.push(obj);
            });

            createMontageConfiguration.ChildAssets = responseResult;
            createMontageConfiguration.AssetId = event.Id;

            await firstValueFrom(
              this.montageBuilderService
                .createMontageImageEditorConfiguration(
                  createMontageConfiguration
                )
                .pipe(take(1))
            ).then(async (data) => {
              await this.offerService.getOffer(this.offerId).pipe(take(1)).subscribe((offer) => {
                let responseOfferDetails = JSON.parse(JSON.stringify(offer));
                this.montageBuilderService.selectedOfferDetailsBehaviorSubject.next(offer);
                if (responseOfferDetails && responseOfferDetails.ImageEditorConfigId) {
                  this.montageBuilderService.montageBuilderCheckConfigureBehaviorSubject.next(true);
                  this.montageBuilderService.montageBuilderResponseBehaviorSubject.next(offer);
                } else {
                  this.montageBuilderService.montageBuilderCheckConfigureBehaviorSubject.next(false);
                  this.montageBuilderService.montageBuilderResponseBehaviorSubject.next(offer);
                }
              });
              this.close();
              this.matSnackBar.open(
                `Successfully Montage saved`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
            }).catch(err => {
              console.warn('create Montage Image Editor Configuration is Failed', err);
            });
          },
          error: async (err: any) => {
            // this.matSnackBar.open(
            //   `Upload Asset is failed, Retry to upload`,
            //   'OK',
            //   DEFAULT_SNACKBAR_CONFIG
            // );
            console.warn('upload Asset is Failed', err);
          },
        });


    } catch (error) {
      this.matSnackBar.open(
        `Montage failed saved`,
        'OK',
        DEFAULT_SNACKBAR_CONFIG
      );
      console.warn('Save Montage Config is Failed', error);
    }
  }
  onDownload() {
    this.clearTransformers();

    this.montageName = this.getFileId();

    var dataURL = this.stage.toDataURL({ mimeType: 'image/png' });
    this.downloadURI(dataURL, `M_${this.montageName}.png`);
  }

  downloadURI(uri: any, name: any) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  }

  openSearchDialog(): void {
    this.montageBuilderService.montageOfferSelectedFromEventBehaviourSubject.next(this.route.snapshot.params['offerId']);
    const dialogRef = this.dialog.open(MontageAssetsListComponent, {
      width: '100%',
      height: '100%',
      data: {
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.length > 0) {


        // Need handle the GetAssets API 
        let urlParams = '';

        for (
          let index = 0;
          index <= result.length - 1;
          index++
        ) {
          urlParams += `assetIds=${result[index]?.Detail.Id}`;
          if (index != result.length - 1) {
            urlParams += '&';
          }
        }

        await firstValueFrom(this.montageBuilderService.GetByAssets(urlParams).pipe(take(1))).then(async (data) => {
          if (data.value.length > 0) {
            // if (data.value[0].Detail.Id) {
            //   this.ImageEditorConfigId = data.value[0].Detail.Id;
            // }

            this.oldMontageConfig = true;
            this.montageShowVersionHistory = data.value[0];
            this.montageBuilderService.montageShowVersionHistoryBehaviorSubject.next(data.value[0]);
            const dialogRef = this.dialog.open(MontageHistoryDialogComponent, {
              width: '40%',
              height: '40%',
              data: data.value[0],
              disableClose: true,
            });
            dialogRef.afterClosed().subscribe(async (data) => {
              if (data == 'close') {
                // selected image need load
                this.oldMontageConfig = false;

                await this.sortImageAndLoadMontage();
              } else {
                // need to load api response in the screen
                this.montageImages = [];
                this.imagesConfigs = {};
                if (this.montageShowVersionHistory.ChildAssets.length > 0) {
                  this.montageShowVersionHistory.ChildAssets.forEach((asset: any) => {
                    let config = JSON.parse(asset.Config);
                    this.imagesConfigs[`${config.montageImageConfig.refId}`] = {
                      ...config.montageImageConfig,
                    };
                    this.montageImages.push(config.montageImageDetails);
                  });
                }
                await this.sortImageAndLoadMontage();
              }
            });

          } else {
            let imageConfigsLength = Object.keys(this.imagesConfigs).length;

            result.map((product: any) => {
              if (product && product.Detail.Id && product.Detail.WebUrl) {
                let objectMontageConfig = {
                  imageName: product.Detail.FileName,
                  wicId: product.Detail.Id,
                  image: product.Detail.WebUrl,
                  ClientKey: product.Detail.ClientKey || ''
                };
                const found = this.montageImages.some(
                  (el) => el.wicId === product.Detail.Id
                );
                if (!found) {
                  this.montageImages.push(objectMontageConfig);

                  let refId = product.Detail.Id;
                  if (refId) {
                    this.imagesConfigs[`${refId}`] = {
                      x: 180,
                      y: 180,
                      refId: refId,
                      scaleX: 0.5,
                      scaleY: 0.5,
                      rotation: 0,
                      layerIndex: imageConfigsLength,
                    };
                    imageConfigsLength = imageConfigsLength + 1;
                  }
                }
              }
            });
            if (this.stage) {
              this.stage.clear();
            }
            await this.sortImageAndLoadMontage();
          }
        })
          .catch((err) => {

          });
      }
    });


  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }
}
