import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest, map, take } from 'rxjs';

import { AuthorizeControlService } from 'pr1-ui-components';
import {
  ConfirmDialogComponent,
  DEFAULT_SNACKBAR_CONFIG,
  Logger,
  NotificationService,
  OrganizationService,
} from 'src/app/@shared';
import { Filter } from 'src/app/@shared/models/filter.model';
import {
  CloneEventComponent,
  EditOfferTagsComponent,
  Event,
  EventService,
  OfferPromoSortDialogComponent,
  OfferTag,
  OfferTagsCount,
  OfferTagService,
  Version,
  VersionListComponent,
  VersionService,
} from '../..';
import { OfferPromo } from 'src/app/modules/standard/v1';
import { PromoDomain } from 'src/app/modules/standard/v1';
import { OfferPromoService } from 'src/app/modules/standard/v1';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Status } from '../../models/offer-promo-status';
import { StatusCount } from '../../models/status-count.model';
import * as dayjs from 'dayjs';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { CloneComponent } from '../clone/clone.component';

const log = new Logger('OffersComponent');

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
})
export class OffersComponent<
  TEvent extends Event,
  Tag extends OfferTag,
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, OnDestroy {
  public offerSorting = [
    { id: { active: 'PromoName', direction: 'asc' }, value: 'Offer Name asc' },
    {
      id: { active: 'PromoName', direction: 'desc' },
      value: 'Offer Name desc',
    },
    { id: { active: 'StartDate', direction: 'asc' }, value: 'Start date asc' },
    {
      id: { active: 'StartDate', direction: 'desc' },
      value: 'Start date desc',
    },
    { id: { active: 'Rank', direction: 'asc' }, value: 'Rank asc' },
    { id: { active: 'Rank', direction: 'desc' }, value: 'Rank desc' },
  ];
  public offerpromos = [
    { name: 'Offers', value: 'OFFER' },
    { name: 'Promos', value: 'PROMO' },
    { name: 'Offer Group', value: 'OFFERGROUP' },
  ];

  public offerstatus = [
    { name: 'Approved', value: 'Approved' },
    { name: 'Pending', value: 'Pending' },
    { name: 'Suppressed', value: 'Suppressed' },
  ];

  public statusCount: StatusCount = {};
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('offerPromoTable', { static: false }) offerPromoTable: any;
  @ViewChild('offerPromoCards', { static: false }) offerPromoCards: any;
  offerTagsListwithCount: OfferTagsCount[] = [];
  eventDetails: any;
  viewModel$ = combineLatest([
    this.eventService.getEvent(this.route.snapshot.params['eventId']),
    this.offerpromoService.isLoading$,
    this.offerpromoService.viewMode$,
    this.offerpromoService.eventId$,
    this.offerpromoService.filters$,
    this.versionService.allVersions$
  ]).pipe(
    map(
      ([event, isLoading, viewMode, eventId, filters, versions]) => {
        this.eventDetails = event;
        return {
          event,
          isLoading,
          viewMode,
          filters,
          versions,
          eventId
        };
      }
    )
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
    versions: new FormControl(),
    offerTags: new FormControl(),
    offerPromos: new FormControl(),
    offerStatus: new FormControl(),
    noTags: new FormControl<boolean>(false),
    withComments: new FormControl<boolean>(false),
  });
  selectedTag = '';
  applicableWorkflowPermissions: any = null;
  awDetails: any = null;
  showStageApproval: boolean = false;
  activeStage: any = null;
  stageCompleted = false;
  stageToMarkActive: any = null;
  readOnlyMode = true;
  permissionExists: boolean = false;
  roles = {
    reviewer: 0,
    approver: 1,
    editor: 2,
  };

  subscription$ = new Subscription();
  userHasAccess: boolean = false;
  selectedVal = '';
  loggedInUsersRole: any;
  gotPermissions: boolean = false;
  permissions: any;
  inProgressWorkflowIndex: any;

  constructor(
    private eventService: EventService<TEvent>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private versionService: VersionService<Version>,
    private offerTagService: OfferTagService<Tag>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private ewService: EventWorkflowService,
    private orgService: OrganizationService,
    private notificationService: NotificationService,
    private authorizationService: AuthorizeControlService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription$.add(this.offerTagService.getByOfferTagsWithCount(
      this.route.snapshot.params['eventId'] || this.offerpromoService.eventId, []
    ).pipe(take(1)).subscribe(data => {
      this.offerTagsListwithCount = data.filter(tag => tag.TagId && tag.TagName);
    }));
    this.getWorkflowPermissions();
    this.processRequiredInitialSetup();
    this.manuallyInvokePermissionsForCurrentWorkflow();

    this.route.queryParams.subscribe((params) => {
      if (params['offer_filters'] && params['offer_filters'] == 'true') {
        let filterSelections = this.offerpromoService.getFilterSelections();
        if (filterSelections) {
          this.filtersForm.patchValue(filterSelections);
        }
      } else {
        this.clearFilters();
      }
    });

    // this.offerpromoService.sort(this.offerSorting[0].id);
  }

  manuallyInvokePermissionsForCurrentWorkflow() {
    if (this.ewService.getSelectedEventId()?.trim().length == 0) {
      this.ewService.setSelectedEventId(
        this.route.snapshot.params['eventId']?.trim()
      );
    }
  }

  navigateOfferGroup() {
    this.router.navigate(['offergroup'], { relativeTo: this.route });
  }

  isSelected(viewModel: any, status: string) {
    return (
      viewModel.filters &&
      viewModel.filters.length > 0 &&
      viewModel.filters.find((x: any) => x.displayText === status)
    );
  }

  onValChange(val: string) {
    this.selectedVal = val;
    const selectedStatus = this.offerstatus.filter((x) => val.includes(x.name));
    this.filtersForm.controls.offerStatus.patchValue(selectedStatus);
    this.filterOfferStatus(selectedStatus);
  }

  processRequiredInitialSetup() {
    this.offerpromoService.toggleViewMode('CARDS');
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.versionService.eventId = this.route.snapshot.params['eventId'];
    this.offerpromoService.page({ pageIndex: 0, pageSize: 50 });
    this.clearSearch();
    this.offerpromoService.statusUpdated$.subscribe((res) => {
      this.getStatusCount();
    });
  }
  getWorkflowPermissions() {
    setTimeout(() => {
      if (!this.permissionExists) {
        this.readOnlyMode = !this.authorizationService.checkAccess('edit||offermang');
      }
    }, 1000);
    if (this.offerPromoTable) {
      this.offerPromoTable.updateReadonlyMode(this.readOnlyMode);
    } else if (this.offerPromoCards) {
      this.offerPromoCards.updateReadonlyMode(this.readOnlyMode);
    }

    this.subscription$.add(
      this.ewService.permissionForCurrentWorkflow$.subscribe(
        (permissions: any) => {
          this.permissions = permissions;
          // If no permissions found, restrict access
          if (!permissions || permissions.length == 0) {
            this.showStageApproval = false;
            return;
          }

          const inProgressWorkflowIndex = permissions.findIndex(
            (p: any) => p.Status == 'in_progress'
          );
          this.inProgressWorkflowIndex = inProgressWorkflowIndex;

          // If an active workflow exists
          if (inProgressWorkflowIndex > -1) {
            this.applicableWorkflowPermissions =
              permissions[inProgressWorkflowIndex];

            this.getWorkflowDetails(
              this.applicableWorkflowPermissions['WorkflowId']
            );
            const stages = this.applicableWorkflowPermissions?.Stages;
            console.log(`stages: `, stages);
            // TODO: Should check for only active stage, need to remove empty and not_started, after updating workflow editor to auto update active to next stage.!
            const activeStageIndex = stages.findIndex(
              (s: any) => s.Status == 'active' || s.Status?.trim() == '' || s.Status == 'not_started'
            );

            // Check if active stage index
            if (activeStageIndex > -1) {
              this.activeStage =
                this.applicableWorkflowPermissions?.Stages[activeStageIndex];

              // Check if logged-in user has permission to access this event
              const userFoundIndex = this.activeStage?.Users?.findIndex(
                (u: any) =>
                  u.Email?.toLowerCase()?.trim() ==
                  this.orgService?.loggedInUser?.email?.toLowerCase().trim()
              );

              let user: any = null;
              let userCanOnlyView = true;

              if (userFoundIndex > -1) {
                // User has access to event in current workflow
                this.userHasAccess = true;

                user = this.activeStage?.Users[userFoundIndex];
                this.loggedInUsersRole = user.Role;
                if (user.Role == this.roles.reviewer) {
                  // User is reviewer & can only view the event
                  userCanOnlyView = true;
                } else {
                  // User is approver & can edit the event
                  userCanOnlyView = false;
                }
              } else {
                // User DOES NOT have access to event in current workflow
                // Allow only read only access
                userCanOnlyView = true;
                this.subscription$.unsubscribe();
              }

              // User is reviewer & hence has only read only access 
              this.readOnlyMode = userCanOnlyView;
              this.permissionExists = true;

              if (this.offerPromoTable) {
                this.offerPromoTable.updateReadonlyMode(this.readOnlyMode);
              } else if (this.offerPromoCards) {
                this.offerPromoCards.updateReadonlyMode(this.readOnlyMode);
              }

              // If user is permitted, continue with stage approval
              if (
                activeStageIndex <
                this.applicableWorkflowPermissions?.Stages?.length - 1
              ) {
                this.stageToMarkActive =
                  this.applicableWorkflowPermissions?.Stages[
                  activeStageIndex + 1
                  ];
              }

              this.showStageApproval = true;

              // Get permissions call doesnt have enough data, hence call get details
              this.getWorkflowDetails(
                this.applicableWorkflowPermissions['WorkflowId']
              );
            }
            // If there is NO Active STAGE - go into default mode
            else {
              this.showStageApproval = false;
            }
          }
          // If no workflow is active, just show events in default mode
          else {
            this.showStageApproval = false; // Stage sign off does not exist in default mode           
          }
          this.gotPermissions = true;
        }
      )
    );
  }

  getWorkflowDetails(workflowId: string) {
    this.subscription$.add(
      this.ewService
        .getWorkflowDetailsForFeature(
          workflowId,
          this.orgService.silentAuthToken
        )
        .subscribe((details: any) => {
          this.awDetails = details;
        })
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CloneComponent, {
      width: '80%',
      height: '90%',
      data: { eventId: this.route.snapshot.params['eventId'] },
    });
  }

  getStatusCount() {
    this.offerpromoService.getStatusCount().subscribe((count) => {
      this.statusCount = count;
    });
  }

  onSearch(event: any) {
    this.offerpromoService.search(event.target.value);
  }

  onSort() {
    const dialogRef = this.dialog.open(OfferPromoSortDialogComponent);
  }
  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.offerpromoService.search('');
  }

  toggleView(mode: string) {
    this.offerpromoService.toggleViewMode(mode);
  }

  clearFilters() {
    this.filtersForm.reset();
    this.offerpromoService.clearFilters();
    this.offerpromoService.setFilterSelections(null);
    this.selectedVal = '';
  }

  removeFilter(filter: Filter) {
    if (filter.fieldName === 'versionId') {
      ///TODO: fix below
      this.filtersForm.patchValue({
        versions: this.filtersForm.controls.versions.value.filter(
          (item: Version) => item.Id !== filter.value
        ),
      });
    }
    if (filter.fieldName === 'status') {
      ///TODO: fix below
      this.filtersForm.patchValue({
        offerStatus: this.filtersForm.controls.offerStatus.value.filter(
          (item: any) =>
            item.name.toLowerCase() !== filter.value.toLocaleLowerCase()
        ),
      });
      this.selectedVal = this.filtersForm.controls.offerStatus.value.map(
        (x: any) => x.name
      );
    }
    this.filtersForm.controls.noTags.setValue(false);
    this.filtersForm.controls.withComments.setValue(false);
    this.offerpromoService.removeFilter(filter);
  }

  onFilterChange(event: any) {
    this.offerpromoService.setFilterSelections(this.filtersForm.value);
    switch (event.source.ngControl.name.toLowerCase()) {
      case 'versions':
        // get the selected version ids
        const versionValues: Version[] = event.value;
        // remove any existing version id filters
        this.offerpromoService.removeFilterByFieldName('versionId');
        // init the array of version filters
        const versionFilters: Filter[] = [];
        // loop through the selected version ids and add each as a filter
        versionValues.forEach((value) => {
          // init the new filter
          const versionFilter: Filter = {
            displayText: value.VersionName,
            fieldName: 'versionId',
            value: value.Id,
          };
          // add the filter to the list of filters
          versionFilters.push(versionFilter);
        });
        // add the filters to the event service
        this.offerpromoService.addFilters(versionFilters);
        break;
      case 'offertags':
        // get the selected offerTag ids
        const offerTagValues: OfferTagsCount[] = event.value;
        //remove any existing offer tags filter
        this.offerpromoService.removeFilterByFieldName('offerTagId');
        // init the array of offerTag filters
        const offerTagFilters: Filter[] = [];
        // loop through the selected offerTag ids and add each as a filter
        offerTagValues.forEach((value) => {
          // init the new filter
          if (value.TagId && value.TagName) {
            const offerTagFilter: Filter = {
              displayText: value.TagName,
              fieldName: 'offerTagId',
              value: value.TagId,
            };
            // add the filter to the list of filters
            offerTagFilters.push(offerTagFilter);
          }
        });
        // add the filters to the event service
        this.offerpromoService.addFilters(offerTagFilters);
        break;
      case 'offerpromos':
        // get the selected offerTag ids
        const eventtypeValues: PromoDomain<any>[] = event.value;
        //remove any existing offer tags filter
        this.offerpromoService.removeFilterByFieldName('eventtype');
        // init the array of offerTag filters
        const eventtypeFilters: Filter[] = [];
        // loop through the selected offerTag ids and add each as a filter
        eventtypeValues.forEach((value: any) => {
          // init the new filter
          const eventtypeFilter: Filter = {
            displayText: value.name,
            fieldName: 'eventtype',
            value: value.value,
          };
          // add the filter to the list of filters
          eventtypeFilters.push(eventtypeFilter);
        });
        // add the filters to the event service
        this.offerpromoService.addFilters(eventtypeFilters);
        break;
      case 'offerstatus':
        this.filterOfferStatus(event.value);
        break;
    }
  }

  filterOfferStatus(event: any) {
    // get the selected status type
    const offerstatusValues: PromoDomain<any>[] = event;
    //remove any existing offer tags filter
    this.offerpromoService.removeFilterByFieldName('status');
    // init the array of offerTag filters
    const statustypeFilters: Filter[] = [];
    // loop through the selected offerTag ids and add each as a filter
    offerstatusValues.forEach((value: any) => {
      // init the new filter
      const statustypeFilter: Filter = {
        displayText: value.name,
        fieldName: 'status',
        value: value.value,
      };
      // add the filter to the list of filters
      statustypeFilters.push(statustypeFilter);
    });
    // add the filters to the event service
    this.offerpromoService.addFilters(statustypeFilters);
    this.selectedVal = this.filtersForm.controls.offerStatus.value.map(
      (x: any) => x.name
    );
  }

  noTags(event: any) {
    this.offerpromoService.removeFilterByFieldName('noTags');
    // init the new filter
    const offerTagFilter: Filter = {
      displayText: 'No Tags',
      fieldName: 'noTags',
      value: event.checked,
    };
    // add the filters to the event service
    this.offerpromoService.addFilters([offerTagFilter]);
    if (!event.checked) {
      this.offerpromoService.removeFilterByFieldName('noTags');
    }
  }
  withComments(event: any) {
    this.offerpromoService.removeFilterByFieldName('hasComments');
    // init the new filter
    const offerTagFilter: Filter = {
      displayText: 'Has Comments',
      fieldName: 'hasComments',
      value: event.checked,
    };
    // add the filters to the event service
    this.offerpromoService.addFilters([offerTagFilter]);
    if (!event.checked) {
      this.offerpromoService.removeFilterByFieldName('hasComments');
    }
  }
  resetFilters(toggleSideNav = true) {
    this.filtersForm.reset();
    this.offerpromoService.search('');
    this.offerpromoService.clearFilters();
    if (toggleSideNav == true) {
      this.sidenav.toggle();
    }
    this.selectedVal = '';
  }

  compareWithIdFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.Id === o2.Id : o1 === o2;
  };

  compareWithValFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  };

  deleteSelectedRecords() {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = this.offerPromoTable.getSelectedSectionRecords();
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = this.offerPromoCards.getSelectedSectionRecords();
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove selected Offer/Promo?`,
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((confirmResult) => {
        if (confirmResult) {
          let bulkDelete: any = [];
          for (
            let index = 0;
            index <= selectedRecordsDetails.length - 1;
            index++
          ) {
            const activeVersion = selectedRecordsDetails[
              index
            ].OfferVariants.find((x: any) => x.activeItem == true);
            if (
              !activeVersion ||
              (activeVersion &&
                activeVersion.Id === selectedRecordsDetails[index].DomainId)
            ) {
              const recordstoupdate = selectedRecordsDetails[
                index
              ].OfferVariants.map((x: any) => {
                return {
                  Id: x.Id,
                  ClientKey: x.ClientKey,
                  UserId: x.UserId,
                  Name: x.Name,
                  Headline: x.Headline,
                  BodyCopy: x.BodyCopy,
                  Disclaimer: x.Disclaimer,
                  ClientComment: x.ClientComment,
                  StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
                  EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
                  Rank: x.Rank,
                  TargetURL: x.TargetURL,
                  Status: x.Status,
                  Version: x.Version,
                  Label: x.Label,
                };
              });
              bulkDelete = [...bulkDelete, ...recordstoupdate];
            } else if (
              activeVersion &&
              activeVersion.Id != selectedRecordsDetails[index].DomainId
            ) {
              const x = activeVersion;
              bulkDelete.push({
                Id: x.Id,
                ClientKey: x.ClientKey,
                UserId: x.UserId,
                Name: x.Name,
                Headline: x.Headline,
                BodyCopy: x.BodyCopy,
                Disclaimer: x.Disclaimer,
                ClientComment: x.ClientComment,
                StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
                EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
                Rank: x.Rank,
                TargetURL: x.TargetURL,
                Status: x.Status,
                Version: x.Version,
                Label: x.Label,
              });
            }
          }
          this.offerpromoService.deleteOfferPromos(bulkDelete).subscribe({
            next: () => {
              this.matSnackBar.open(
                `Offer/Promo(s) deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.offerpromoService.reload();
              if (this.offerPromoTable) {
                this.offerPromoTable.clearSelection();
              } else if (this.offerPromoCards) {
                this.offerPromoCards.clearSelection();
              }
              this.getStatusCount();
            },
            error: (error) => {
              log.error('Error in deleting Offer/Promo', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
        }
      });
    }
  }

  downloadOffersJSON() {
    this.offerpromoService.downloadOffersJSON(
      this.route.snapshot.params['eventId']
    );
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoTable.getSelectedSectionRecords())
      );
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoCards.getSelectedSectionRecords())
      );
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      let statustoUpdate: any = [];
      for (let index = 0; index <= selectedRecordsDetails.length - 1; index++) {
        const activeVersion = selectedRecordsDetails[index].OfferVariants.find(
          (x: any) => x.activeItem == true
        );
        if (
          !activeVersion ||
          (activeVersion &&
            activeVersion.Id === selectedRecordsDetails[index].DomainId)
        ) {
          const recordstoupdate = selectedRecordsDetails[
            index
          ].OfferVariants.map((x: any) => {
            return {
              Id: x.Id,
              ClientKey: x.ClientKey,
              UserId: x.UserId,
              Name: x.Name,
              Headline: x.Headline,
              BodyCopy: x.BodyCopy,
              Disclaimer: x.Disclaimer,
              ClientComment: x.ClientComment,
              StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
              EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
              Rank: x.Rank,
              TargetURL: x.TargetURL,
              Status: status,
              Version: x.Version,
              Label: x.Label,
            };
          });
          statustoUpdate = [...statustoUpdate, ...recordstoupdate];
        } else if (
          activeVersion &&
          activeVersion.Id != selectedRecordsDetails[index].DomainId
        ) {
          const x = activeVersion;
          statustoUpdate.push({
            Id: x.Id,
            ClientKey: x.ClientKey,
            UserId: x.UserId,
            Name: x.Name,
            Headline: x.Headline,
            BodyCopy: x.BodyCopy,
            Disclaimer: x.Disclaimer,
            ClientComment: x.ClientComment,
            StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
            EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'),
            Rank: x.Rank,
            TargetURL: x.TargetURL,
            Status: status,
            Version: x.Version,
            Label: x.Label,
          });
        }
      }

      this.offerpromoService.updateStatus(statustoUpdate).subscribe({
        next: () => {
          this.offerpromoService.reload();
          if (this.offerPromoTable) {
            this.offerPromoTable.clearSelection();
          } else if (this.offerPromoCards) {
            this.offerPromoCards.clearSelection();
          }
          this.getStatusCount();
        },
      });
    }
  }

  downloadPromosJSON() {
    this.offerpromoService.downloadPromosJSON(
      this.route.snapshot.params['eventId']
    );
  }

  downloadEventJSON() {
    this.offerpromoService.downloadEventJSON(
      this.route.snapshot.params['eventId']
    );
  }

  downloadCSV() {
    this.offerpromoService.downloadCSV(this.route.snapshot.params['eventId']);
  }

  downloadCommentsCSV() {
    this.offerpromoService.downloadCommentsCSV(this.route.snapshot.params['eventId']);
  }

  searchOfferTags(event: any) {
    this.offerTagService.search(event.target.value);
  }

  tagSelected(event: any) {
    this.offerpromoService.removeFilterByFieldName('offerTagId');
    this.selectedTag = event.option.value.OfferTagName;
    this.filtersForm['controls']['offerTags'].setValue(event.option.value.Id);
    const offerTagFilters: Filter[] = [
      {
        displayText: event.option.value.OfferTagName,
        fieldName: 'offerTagId',
        value: event.option.value.Id,
      },
    ];
    // add the filters to the event service
    this.offerpromoService.addFilters(offerTagFilters);
  }

  openVersionsDialog(): void {
    let selectedRecordsDetails;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoTable.getSelectedSectionRecords())
      );
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoCards.getSelectedSectionRecords())
      );
    }
    const dialogRef = this.dialog.open(VersionListComponent, {
      width: '80%',
      height: '90%',
      data: {
        eventId: this.route.snapshot.params['eventId'],
        selectedRecordsDetails,
      },
    });
    this.versionService.search('');
    this.route.params.subscribe((params) => {
      this.versionService.eventId = params['eventId'];
    });
  }

  openofferTagsDialog() {
    let selectedRecordsDetails;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoTable.getSelectedSectionRecords())
      );
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(
        JSON.stringify(this.offerPromoCards.getSelectedSectionRecords())
      );
    }
    const dialogRef = this.dialog.open(EditOfferTagsComponent, {
      width: '80%',
      height: '90%',
      autoFocus: false,
      data: {
        eventId: this.route.snapshot.params['eventId'],
        selectedRecordsDetails,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.subscription$.add(this.offerTagService.getByOfferTagsWithCount(
        this.route.snapshot.params['eventId'] || this.offerpromoService.eventId, []
      ).pipe(take(1)).subscribe((results) => {
        this.offerTagsListwithCount = results.filter(tag => tag.TagId && tag.TagName);
      }));
      if (this.offerPromoTable) {
        this.offerPromoTable.clearSelection();
      } else if (this.offerPromoCards) {
        this.offerPromoCards.clearSelection();
      }
    });
  }

  markStageAsComplete() {
    console.log(`this.activeStage: `, this.activeStage);
    const workflowId = this.awDetails.Id;
    const taskId = this.activeStage?.StageId;
    const stageName = this.activeStage?.StageName;
    /** Logs for testing */
    console.log('Clicked on MarkStageAsComplete. Details: ', {
      workflowId,
      taskId,
      stageName,
      eventId: this.route.snapshot.params['eventId'],
      eventName: this.eventDetails?.EventName,
    });

    const message = `Are you sure you wish to mark this stage as complete?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Mark Complete',
        message: message,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      },
    });

    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.onConfirmMarkStageAsComplete();
        }
      })
    );
  }

  onConfirmMarkStageAsComplete() {
    console.log('Confirmed Mark Stage As Complete');
    this.triggerEmailForActiveUsersInNextStage();
    const workflowId = this.awDetails.Id;
    const taskId = this.activeStage?.StageId;
    const stageName = this.activeStage?.StageName;
    const stageCompleteMsg = `Stage <b>${stageName}</b> is completed for event <b>${this.ewService.eventName}</b>`;
    this.subscription$.add(
      this.ewService
        .updateStageStatus(
          workflowId,
          taskId!,
          this.orgService.silentAuthToken,
          'completed',
          stageCompleteMsg
        )
        .subscribe((resp: any) => {
          this.stageCompleted = true;
          this.notificationService.showSuccess(
            `${this.awDetails?.TemplateName} - ${stageName} complete`
          );
          if (this.stageToMarkActive != null) {
            this.subscription$.add(
              this.ewService
                .updateStageStatus(
                  workflowId,
                  this.stageToMarkActive?.StageId,
                  this.orgService.silentAuthToken,
                  'active',
                  `Stage <b>${this.stageToMarkActive?.StageName}</b> is marked Active for event ${this.ewService.eventName}</b>`
                )
                .subscribe((markActiveResponse: any) => {
                  // this.triggerEmailForActiveUsersInNextStage();
                  setTimeout(() => {
                    this.router.navigate([`../../`], {
                      relativeTo: this.route,
                    });
                    this.stageCompleted = false;
                    this.resetFilters(false);
                    this.ngOnInit();
                  }, 3000);
                })
            );
          } else {
            setTimeout(() => {
              this.router.navigate([`../../`], {
                relativeTo: this.route,
              });
              this.stageCompleted = false;
              this.resetFilters(false);
              this.ngOnInit();
            }, 3000);
          }
        })
    );
  }

  triggerEmailForActiveUsersInNextStage() {
    const stages = this.applicableWorkflowPermissions?.Stages;
    const activeStageIndex = stages.findIndex((s: any) => s.Status == 'active');

    if (
      activeStageIndex != undefined &&
      activeStageIndex > -1 &&
      stages.length > activeStageIndex + 1
    ) {
      const stageToMarkActive =
        this.applicableWorkflowPermissions?.Stages[activeStageIndex + 1];
      const urlWithDomainAndEventId = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/')
      );

      const eventId = window.location.hash.split('/')[3];

      // const emailTemplateString = `<html><body>Stage <b>Stage ${stageToMarkActive.StageName
      //   }</b> of Workflow <b>${this.awDetails?.TemplateName // -------------------> GetWorkflowName!
      //   }</b>,  is now Active.<br /> Access the Event here:<br />  ${urlWithDomainAndEventId}/offers</body></html>`;

      const emailTemplateString = this.ewService.getEmailBody(
        this.ewService.eventName,
        this.awDetails?.TemplateName,
        stageToMarkActive.StageName,
        `${urlWithDomainAndEventId}/offers`
      );

      const inappTemplateString = this.ewService.getInappBody(
        this.ewService.eventName,
        this.awDetails?.TemplateName,
        stageToMarkActive.StageName,
        `${urlWithDomainAndEventId}/offers`
      );

      this.ewService.triggerCustomKnockNotification(
        stageToMarkActive.users?.map((u: any) =>
          (u.email as string)?.toLowerCase()?.trim()
        ),
        emailTemplateString,
        '$users',
        'pr1-notification',
        inappTemplateString
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  ExportSelectedGalleyProof() {
    let selectedOfferPromos = []
    if (this.offerPromoTable) {
      selectedOfferPromos = this.offerPromoTable.getSelectedSectionRecords().map((record: any) => record.DomainId);
    } else if (this.offerPromoCards) {
      selectedOfferPromos = this.offerPromoCards.getSelectedSectionRecords().map((record: any) => record.DomainId);
    }
    if (selectedOfferPromos.length) {
      this.offerpromoService.ExportSelectedGalleyProof(this.eventDetails.Id, selectedOfferPromos).subscribe((res: any) => {
        const blob = new Blob([res], { type: res.type });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'galleyProof';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      })
    }
  }

  exportGalleyProof() {
    this.offerpromoService.exportAllGalleyProof(this.eventDetails.Id).subscribe(res => {
      const blob = new Blob([res], { type: res.type });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'galleyProof';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    })
  }
}
