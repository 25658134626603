<div class="container">
  <p>Previously created montage exists with these selected assets</p>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button type="button" mat-stroked-button color="accent" matTooltip="Show Version History"
      data-testid="Show Version History" (click)="ShowVersionHistory()">
      Show Version History
    </button>
    <button type="button" mat-stroked-button color="accent" matTooltip="Create New Version"
      data-testid="Create New Version" (click)="CreateNewVersion()">
      Create New Version
    </button>
  </div>
</div>