import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { OrganizationService } from 'src/app/@shared';


@Injectable({
    providedIn: 'root',
})

export class JunctionAiService {
    constructor(private orgService: OrganizationService) {
        this.junctionAiBehaviorSubject.next(this.orgService.organization?.enableJunctionAI);
    }
    private junctionAiBehaviorSubject = new BehaviorSubject(
        true as boolean | null | undefined
    );

    public useJunctionAi$ = this.junctionAiBehaviorSubject.asObservable();

    set(value: any): void {
        // here is where we will reach out to the private API to verify if the user has access to Junction AI functionality
        this.junctionAiBehaviorSubject.next(value);
    }

    get(): any {
        return this.junctionAiBehaviorSubject.value;
    }
}
