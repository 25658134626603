<!-- actions bar -->
<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar" style="height: 50px;">
  <button style="margin-left: 10px !important;" mat-button [matMenuTriggerFor]="menu">File</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>New Design File</button>
    <button mat-menu-item (click)="saveUniqueMontage()">Save As Unique Montage</button>
    <button mat-menu-item (click)="onDownload()">Download</button>
    <button mat-menu-item (click)="showVersionHistory()">Show Version History</button>
  </mat-menu>
  <span class="menu-spacer"></span>
  <div class="zoom-option">
    <label for="" class="label-title">Zoom</label>
    <input
      type="range"
      class="float-right"
      step="0.01"
      min="0.1"
      max="0.7"
      [(ngModel)]="viewScale"
    />
   
    <label for="range" id="value"></label>
  </div>
  <button class="btn btn-primary" style="margin-right: 10px !important;" (click)="close()">Close</button>
  <button class="btn btn-primary" style="margin-right: 10px !important;" (click)="saveMontageConfig()">Save</button>
</div>
<div class="main">
  <div class="main_sidebar">
    <div>
      <span [ngClass]="{'active': layoutSelected == 'Layout' }"  style="    display: grid;
      grid-template-rows: 50% 50%;
      grid-gap: 10p;
      justify-content: center;
      padding: 25px;" (click)="layoutDesign('Layout')"><span style="    margin-left: 15px;
    margin-right: 5px;"><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.78125 1.15625C8.26042 0.947917 8.75 0.947917 9.25 1.15625L16.0625 4.3125C16.3333 4.45833 16.4792 4.6875 16.5 5C16.4792 5.3125 16.3333 5.54167 16.0625 5.6875L9.21875 8.84375C8.73958 9.05208 8.25 9.05208 7.75 8.84375L0.9375 5.6875C0.666667 5.54167 0.520833 5.3125 0.5 5C0.520833 4.6875 0.666667 4.45833 0.9375 4.3125L7.78125 1.15625ZM14.4062 7.5625L16.0625 8.3125C16.3333 8.45833 16.4792 8.6875 16.5 9C16.4792 9.3125 16.3333 9.54167 16.0625 9.6875L9.21875 12.8438C8.73958 13.0521 8.25 13.0521 7.75 12.8438L0.9375 9.6875C0.666667 9.54167 0.520833 9.3125 0.5 9C0.520833 8.6875 0.666667 8.45833 0.9375 8.3125L2.59375 7.5625L7.34375 9.75C8.11458 10.0833 8.88542 10.0833 9.65625 9.75L14.4062 7.5625ZM9.65625 13.75L14.4062 11.5625L16.0625 12.3125C16.3333 12.4583 16.4792 12.6875 16.5 13C16.4792 13.3125 16.3333 13.5417 16.0625 13.6875L9.21875 16.8438C8.73958 17.0521 8.25 17.0521 7.75 16.8438L0.9375 13.6875C0.666667 13.5417 0.520833 13.3125 0.5 13C0.520833 12.6875 0.666667 12.4583 0.9375 12.3125L2.59375 11.5625L7.34375 13.75C8.11458 14.0833 8.88542 14.0833 9.65625 13.75Z"
              fill="white" />
          </svg>
        </span>
        <div style="    margin-left: 5px;margin-top: 5px;">Layout</div>
      </span>
      <!-- <span [ngClass]="{'active': layoutSelected == 'Assets'}"  style="    display: grid;
      grid-template-rows: 50% 50%;
      grid-gap: 10p;
      justify-content: center;
      padding: 25px;" (click)="layoutDesign('Assets')"><span style="    margin-left: 15px;
    margin-right: 5px;">
          <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.5 2C0.520833 1.4375 0.71875 0.96875 1.09375 0.59375C1.46875 0.21875 1.9375 0.0208333 2.5 0H14.5C15.0625 0.0208333 15.5312 0.21875 15.9062 0.59375C16.2812 0.96875 16.4792 1.4375 16.5 2V12C16.4792 12.5625 16.2812 13.0312 15.9062 13.4062C15.5312 13.7812 15.0625 13.9792 14.5 14H2.5C1.9375 13.9792 1.46875 13.7812 1.09375 13.4062C0.71875 13.0312 0.520833 12.5625 0.5 12V2ZM10.625 5.34375C10.4583 5.11458 10.25 5 10 5C9.72917 5 9.52083 5.11458 9.375 5.34375L6.65625 9.3125L5.84375 8.28125C5.67708 8.09375 5.47917 8 5.25 8C5.02083 8 4.82292 8.09375 4.65625 8.28125L2.65625 10.7812C2.48958 11.0312 2.45833 11.2917 2.5625 11.5625C2.70833 11.8333 2.9375 11.9792 3.25 12H6.25H7.25H13.75C14.0417 12 14.2604 11.8646 14.4062 11.5938C14.5312 11.3229 14.5208 11.0729 14.375 10.8438L10.625 5.34375ZM4 5C4.5625 4.97917 5 4.72917 5.3125 4.25C5.5625 3.75 5.5625 3.25 5.3125 2.75C5 2.27083 4.5625 2.02083 4 2C3.4375 2.02083 3 2.27083 2.6875 2.75C2.4375 3.25 2.4375 3.75 2.6875 4.25C3 4.72917 3.4375 4.97917 4 5Z"
              fill="white" />
          </svg>
        </span>
        <div style="    margin-left: 5px;margin-top: 5px;">Assets</div>
      </span> -->
      <!--
        <li (click)="layoutDesign('Properties')"><span><svg width="13" height="14" viewBox="0 0 13 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.5 0H6.5H11.5C11.7917 0 12.0312 0.09375 12.2188 0.28125C12.4062 0.46875 12.5 0.708333 12.5 1C12.5 1.29167 12.4062 1.53125 12.2188 1.71875C12.0312 1.90625 11.7917 2 11.5 2H7.5V13C7.5 13.2917 7.40625 13.5312 7.21875 13.7188C7.03125 13.9062 6.79167 14 6.5 14C6.20833 14 5.96875 13.9062 5.78125 13.7188C5.59375 13.5312 5.5 13.2917 5.5 13V2H1.5C1.20833 2 0.96875 1.90625 0.78125 1.71875C0.59375 1.53125 0.5 1.29167 0.5 1C0.5 0.708333 0.59375 0.46875 0.78125 0.28125C0.96875 0.09375 1.20833 0 1.5 0Z"
              fill="white" />
          </svg>
        </span>Properties</li>
      <li (click)="layoutDesign('Resize')"><span><svg width="17" height="16" viewBox="0 0 17 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.5 16C7.04167 15.9792 5.70833 15.625 4.5 14.9375C3.29167 14.2292 2.3125 13.25 1.5625 12C0.854167 10.7292 0.5 9.39583 0.5 8C0.5 6.60417 0.854167 5.27083 1.5625 4C2.3125 2.75 3.29167 1.77083 4.5 1.0625C5.70833 0.375 7.04167 0.0208333 8.5 0C9.95833 0.0208333 11.2917 0.375 12.5 1.0625C13.7083 1.77083 14.6875 2.75 15.4375 4C16.1458 5.27083 16.5 6.60417 16.5 8C16.5 9.39583 16.1458 10.7292 15.4375 12C14.6875 13.25 13.7083 14.2292 12.5 14.9375C11.2917 15.625 9.95833 15.9792 8.5 16ZM7.25 10.5C6.79167 10.5417 6.54167 10.7917 6.5 11.25C6.54167 11.7083 6.79167 11.9583 7.25 12H9.75C10.2083 11.9583 10.4583 11.7083 10.5 11.25C10.4583 10.7917 10.2083 10.5417 9.75 10.5H9.5V7.75C9.45833 7.29167 9.20833 7.04167 8.75 7H7.25C6.79167 7.04167 6.54167 7.29167 6.5 7.75C6.54167 8.20833 6.79167 8.45833 7.25 8.5H8V10.5H7.25ZM8.5 4C8.20833 4 7.96875 4.09375 7.78125 4.28125C7.59375 4.46875 7.5 4.70833 7.5 5C7.5 5.29167 7.59375 5.53125 7.78125 5.71875C7.96875 5.90625 8.20833 6 8.5 6C8.79167 6 9.03125 5.90625 9.21875 5.71875C9.40625 5.53125 9.5 5.29167 9.5 5C9.5 4.70833 9.40625 4.46875 9.21875 4.28125C9.03125 4.09375 8.79167 4 8.5 4Z"
              fill="white" />
          </svg>
        </span>Resize</li>
        -->
    </div>
  </div>

  <div class="left-section">
    <div class="section">

      <div class="layout-section"  *ngIf="layoutSelected=='Layout'">
        <div class="layer-section bold"> Layers</div>
        <div class="layer-image">
          <app-image-layers [montageImages]="montageImages" [imagesConfigs]="imagesConfigs"
            (onChange)="onReorder()" (onDelete)="onDelete($event)"  ></app-image-layers>
        </div>
        <button type="button" style="    width: 95% !important;" mat-raised-button color="accent"
        matTooltip="Add new Assets" data-testid="New Asset" (click)="openSearchDialog()">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Assets
      </button>
      </div>
      
      <!--
       <div class="properties-section" *ngIf="layoutSelected=='Properties'">
        <div  class="bold">Properties Section</div>
      </div>
      <div class="resize-section" *ngIf="layoutSelected=='Resize'">
        <div class="bold">Resize Section</div>
      </div>
      -->
    </div>
  </div>
  <div class="right-section" id="right-section">
    <div class="konvajs-wrap" [style.scale]="viewScale"
      style="display: flex; justify-content: center; transform-origin: top">
      <div id="container"></div>
    </div>
  </div>
</div>