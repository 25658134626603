import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

type montageImage = { id: string, image: string, name: string, wicId: string };

@Component({
  selector: 'app-image-layers',
  templateUrl: './montage-image-layers.component.html',
  styleUrls: ['./montage-image-layers.component.scss']
})
export class MontageImageLayersComponent implements OnInit, OnChanges {
  @Input() montageImages: montageImage[] = [];
  @Input() imagesConfigs: any;


  localImageRefs: montageImage[] = [];

  @Output() onChange = new EventEmitter();
  @Output() onDelete = new EventEmitter<any>();
  constructor() { }
  ngOnInit(): void {

  }
  getImageName(imgStr: any) {
    return imgStr.split('/').pop().split('?')[0] || '';
  }

  ngOnChanges(changes: SimpleChanges) {
    this.localImageRefs = JSON.parse(JSON.stringify(this.montageImages)); // deep clone
    this.localImageRefs = this.localImageRefs.reverse();    
  }

  onElementDrop(event: CdkDragDrop<string[]>) {
    let prevIndex = event.previousIndex,
      currIndex = event.currentIndex;
    moveItemInArray(this.localImageRefs, prevIndex, currIndex);
    this.updateIndexToConfig();
  }
  deleteImage(selectedImage:any){
  
   this.onDelete.emit(selectedImage);
  }

  updateIndexToConfig() {
    if (!this.imagesConfigs) this.imagesConfigs = {};

    let montageImages = [...this.localImageRefs].reverse();
    //let montageImages = this.localImageRefs;
    for (let mi = 0; mi < montageImages.length; mi++) {

      const mImg = montageImages[mi];
      let refId = mImg.wicId;
      if (!this.imagesConfigs[refId]) this.imagesConfigs[refId] = {};
      this.imagesConfigs[refId]["layerIndex"] = mi;
    }
    this.onChange.emit();
  }
  getScaleValue(val: any) {
    return Math.round(val);
  }
}
