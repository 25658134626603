import { AfterContentInit, AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MontageBuilderService } from '../../services/montage-builder.service';
import { firstValueFrom, take } from 'rxjs';
import Konva from 'konva';
import { AssetsDomainService } from '../../services/assets-domain.service';
import { AssetDomain } from '../../models/asset-domain.model';
import { DomSanitizer } from '@angular/platform-browser';
type montageImage = {
  id: string;
  image: string;
  name: string;
  wicId: string;
  imageName?: string;
  ClientKey: string;
};
type imgDrawConfig = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  currentScale: number;
};
@Component({
  selector: 'app-montage-history-preview',
  templateUrl: './montage-history-preview.component.html',
  styleUrls: ['./montage-history-preview.component.scss']
})
export class MontageHistoryPreviewComponent implements OnInit {
  imageUrl:any;
  constructor(public matDialogRef: MatDialogRef<MontageHistoryPreviewComponent>,private domSanitizer:DomSanitizer, private montageBuilderService: MontageBuilderService, @Inject(MAT_DIALOG_DATA) public data: any,private assetsDomainService:AssetsDomainService<AssetDomain>) { }
  ngOnInit() {
    this.imageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.imageSrc);
  }
  Apply() {
    this.montageBuilderService.montageCreateNewVersionBehaviourSubject.next(false);
    // Need to Check wheather data is there any previous then clear the montages and imagesconfig and set new data into montage builder
    this.matDialogRef.close('apply');
  }
  Cancel() {
    this.montageBuilderService.montageCreateNewVersionBehaviourSubject.next(true);
    this.matDialogRef.close('close');
  }
}
